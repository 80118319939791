import { getAreas } from "@/apis/water-area";
export function getArea() {
  //获取区域信息
  interface IAreaItem {
    id: string | number;
    name: string;
    enabled: boolean;
  }
  const areaList: IAreaItem[] = [];
  return new Promise((resolve) => {
    getAreas().then((res: any) => {
      if (res.succeeded) {
        res.data.forEach((item: any) => {
          if (item.enabled) {
            areaList.push(item);
          }
        });
        resolve(areaList)
      }
    });
  })
}
