//用水性质

export const useWaterType = {
  0: "生活用水",
  1: "商业用水",
  2: "综合用水",
  3: "定额用水",
  4: "特殊用水",
};

//计费方式
export const billingType = {
  0: "按量计费",
  1: "定额计费",
};

//计费周期
export const billingCycleType = {
  0: "季度",
  1: "半年",
  2: "全年",
};


//支付方式
export const paymentMethod = {
  0: "无需支付",
  1: "现场支付",
  2: "微信扫码",
  3: "支付宝扫码",
  4: "大厅缴费",
};