import request from "@/utils/request";

/**
 * @description: 获取所有区域名字及其对应编号
 * @param {*} query
 */
export function getAreas() {
  return request({
    url: "/api/WaterArea/GetAreas",
    method: "get",
  });
}

/**
 * @description: 新增区域
 * @param {*} data
 */
interface IAddArea {
  name: string
}
export function addArea(data: IAddArea) {
  return request({
    url: "/api/WaterArea/AddArea",
    method: "post",
    data,
  });
}
/**
 * @description: 修改区域（暂时不用）
 * @param {*} data
 */
interface IUpdateArea {
  "id": number,
  "name": string,
  "enabled": boolean
}
export function updateArea(data: IUpdateArea) {
  return request({
    url: "/api/WaterArea/UpdateArea",
    method: "post",
    data,
  });
}
