import request from "../utils/request.js";

/**
 * @description: 获取所有区域名字及其对应编号
 * @param {*} query
 */
interface addParams {
  name?: string,
  phone?: string,
  address?: string,
  areaId?: number | string,
  useWaterType?: number | string,
  billingType?: number | string,
  billingCycleType?: number | string,
  perPrice?: number | string,
  lastDosage?: number | string,
  lastRecordTime?: string,
  id?: number | string
}
export function add(params: addParams) {
  return request({
    url: "/api/WaterUnit/Add",
    method: "post",
    data: params,
  });
}

//修改水表信息
export function update(params: addParams) {
  return request({
    url: "/api/WaterUnit/Update",
    method: "post",
    data: params,
  });
}

//获取当前水表信息
export function get(id: number | string) {
  return request({
    url: "/api/WaterUnit/Get",
    method: "get",
    params: { id },
  });
}


//获取当前水表信息
interface IEnable {
  id: number,
  enable: boolean
}
export function enable(params: IEnable) {
  return request({
    url: "/api/WaterUnit/Enable",
    method: "post",
    data: params,
  });
}


//获取当前水表信息
export function batchChangePrice(params: any) {
  return request({
    url: "/api/WaterUnit/BatchChangePrice",
    method: "post",
    data: params,
  });
}